import { Component, OnInit } from '@angular/core';
import { gsap, ScrollTrigger } from 'gsap/all';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    const timeline = gsap.timeline({ defaults: { duration: 1, y: -50, opacity: 0 } });
    timeline.from('.home-header-animate', { stagger: 0.6 });
  }

}
