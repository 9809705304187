<footer>
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-lg-2 col-lg-4 footer-about">
          <img class="logo-footer svg-icon" src="assets/svgs/logo.svg" alt="logo-footer">
          <p>
            We are a young company always looking for new and creative ideas to help you with our services in your
            everyday work.
          </p>
        </div>
        <div class="col-md-6 col-lg-4 offset-lg-1 footer-contact">
          <h3>Contact</h3>
          <p><img src="assets/svgs/location.svg">Unit 33 Lonrho Building,<br>Airlines Cargo Centre. Northern Parimeter
            Rd.<br>Or Tambo International Kempton Park<br>South Africa</p>
          <p><img src="assets/svgs/phone.svg">+27 11 791 9125</p>
          <p><img src="assets/svgs/mail.svg"><a
            href="mailto:hello@aerooceanlogistics.com">hello@aerooceanlogistics.com</a></p>
          <p><img src="assets/svgs/whatsapp.svg"> <a
              href="https://wa.me/27832768498?text=Hi%20Orlando%2C%20I%20would%20like%20to%20contact%20you%20regarding%20services%20you%20provide%20as%20Aero-Ocean" target="_blank">click to chat with us</a></p>
        </div>
      </div>
    </div>
  </div>
</footer>
