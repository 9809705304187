import { Component, OnInit } from '@angular/core';
import { gsap } from 'gsap';
@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss']
})
export class WelcomeMessageComponent implements OnInit {

  constructor() {
  }
  ngOnInit() {
    const timeline = gsap.timeline({defaults: {  x: -100 , opacity: 0}});
    timeline.from('.welcome-animate', { stagger: 0.5})
            .from('.welcome-animate-img', {x: 100});
  }

}
