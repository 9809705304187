<section class="row mx-3">
  <div class="card border-0">
    <div class="card-header border-0 bg-transparent">
      <h4 class="my-0 font-weight-normal">Our Offices</h4>
    </div>
    <div class="card-body row">
      <div class="col-6">
        <label for="countries">Country</label>
        <select class="form-select" aria-label="Default select example">
          <option *ngFor="let item of countries" [value]="item">{{item.name}}</option>
        </select>
      </div>
      <div class="col-6">
        <label for="cities">City</label>
        <select class="form-select" aria-label="Default select example">
          <option *ngFor="let item of cities" [value]="item">{{item.name}}</option>
        </select>
      </div>
    </div>
  </div>
</section>



