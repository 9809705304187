import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-offices',
  templateUrl: './our-offices.component.html',
  styleUrls: ['./our-offices.component.scss']
})
export class OurOfficesComponent {

  countries = [{
    id: 1,
    name: 'South Africa',
    code: 'ZA'
   },
   {
    id: 2,
    name: 'Zimbabwe',
    code: 'ZW'
   } ];

   cities = [{
    id: 1,
    name: 'Johannesburg',
    code: 'JHB'
   },
   {
    id: 2,
    name: 'Harare',
    code: 'HRE'
   } ];


  constructor() { }

}
