<section class="row row-cols-1 row-cols-md-3 mx-3 text-center section-100 card-deck">
  <div class="col mb-4" *ngFor="let item of services">
    <div class="card h-100 card-shadow">
      <div class="card-body">
        ​<picture>
          <source srcset="{{item.icon}}" type="image/svg+xml">
          <img src="{{item.icon}}" class="img-fluid mb-2">
        </picture>
        <h4 class="mt-0 font-weight-normal text-muted mb-2">{{item.title}}</h4>
        <div *ngFor="let lines of item.content">
          <p>{{lines.line}}</p>
        </div>

      </div>
      <div class="card-footer bg-transparent border-0">
        <button type="button" class="btn btn-lg btn-block btn-outline-primary border-0" [routerLink]="['/contact']" routerLinkActive="router-link-active" >Contact us</button>
      </div>
    </div>
  </div>
</section>
