// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey: 'AIzaSyCNxSUqKlWixMc7a0GtSkBo1rz3WjDedE4',
  authDomain: 'aerooceanlogistics-86be9.firebaseapp.com',
  projectId: 'aerooceanlogistics-86be9',
  storageBucket: 'aerooceanlogistics-86be9.appspot.com',
  messagingSenderId: '392757242159',
  appId: '1:392757242159:web:78bd023a365b7bd2da99da',
  measurementId: 'G-ZCEZT9WVP8'
};
