<section class="container">
  <div class="row my-lg-4">
    <div class="col-lg-6 col-md-8 mx-auto">
      <h1 class="font-weight-light contact-header-animate">Get in touch <span class="text-muted">with us.</span></h1>
      <p class="lead text-muted contact-header-animate">If you have any questions or queries a member of staff will always
        be happy to help. Feel free to contact us by telephone or email and we will be sure to get back to you as soon
        as possible.</p>
    </div>
  </div>
  <div class="row px-3 g-5">
    <div class="col-md-8 col-lg-6">
      <form [formGroup]="contactForm" (submit)="onSubmit()" class="card border-0">
        <div class="card-body">
          <div class="form-group my-3">
            <label for="name">Name</label>
            <input formControlName="name" type="text" class="form-control" id="name" placeholder="Name">
          </div>
          <div class="form-group my-3">
            <label for="emailAddress">Email address</label>
            <input formControlName="email" type="email" class="form-control" id="emailAddress"
              aria-describedby="emailHelp" placeholder="Enter email">
            <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>
          <div class="form-group my-3">
            <label for="phone">Phone Number</label>
            <input formControlName="phoneNumber" type="text" class="form-control" id="phone" placeholder="Phone Number">
          </div>
          <div class="form-group">
            <label for="serviceType">Service</label>
            <select formControlName="service" class="form-control custom-select" id="serviceType">
              <option *ngFor="let item of ourServices" [value]="item.name">{{item.name}}</option>
            </select>
          </div>
          <div class="form-group my-3">
            <label for="message">Message</label>
            <textarea formControlName="message" class="form-control" id="message" rows="3"></textarea>
          </div>
          <button type="submit" [disabled]="contactForm.invalid" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
    <div class="col-md-8 col-lg-6 ">
      <div class="card border-0">
        <div class="card-body">
          <div class="contact-us-animate row d-flex my-3">
            <img src="assets/img/contact-us.jpg" class=" img-fluid h-75 mx-75 rounded mx-auto d-block" alt="Contact us">
          </div>
          <div class="contact-us-animate row d-flex my-3">
            <p class="col-11 text-right align-self-center">Unit 33 Lonrho Building, Airlines Cargo Centre. Northern
              Parimeter
              Rd. Or Tambo International Kempton Park South Africa</p>
            <img class="col-1 align-self-center" src="assets/svgs/location.svg">
          </div>
          <div class="contact-us-animate row d-flex my-3">
            <p class="col-11 text-right align-self-center">+27 11 791 9125</p>
            <img class="col-1 my-auto align-self-center" src="assets/svgs/phone.svg">

          </div>
          <div class="row d-flex my-3 contact-us-animate">
            <a class="col-11 text-right align-self-center"
              href="mailto:hello@aerooceanlogistics.com">hello@aerooceanlogistics.com</a>
            <img class="col-1 my-auto align-self-center" src="assets/svgs/mail.svg">

          </div>
          <div class="row d-flex my-3 contact-us-animate">
            <a class="col-11 text-right align-self-center"
              href="https://wa.me/27832768498?text=Hi%20Orlando%2C%20I%20would%20like%20to%20contact%20you%20regarding%20services%20you%20provide%20as%20Aero-Ocean"
              target="_blank">Click to Chat on Whatsapp</a>
            <img class="col-1 my-auto align-self-center" src="assets/svgs/whatsapp.svg">
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
