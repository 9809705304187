import { Directive } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms';

export const emailPhoneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const email = control.get('email');
  const phoneNumber = control.get('phoneNumber');

  return email || phoneNumber ? null :
    { errorMessage: 'Please provide either your email address or your phone number in order for our team to get in touch with you.' };
};
@Directive({
  selector: '[appContactValidity]'
})
export class ContactValidityDirective implements Validator {

  constructor() { }
  validate(control: AbstractControl): ValidationErrors {
    return emailPhoneValidator(control);
  }
}
