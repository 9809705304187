import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { emailPhoneValidator } from 'src/app/contact-validity.directive';
import { gsap } from 'gsap';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  ourServices = [{
    id: 1,
    name: 'Air Freight',
  },
  {
    id: 2,
    name: 'Sea Freight',
  },
  {
    id: 3,
    name: 'Road Freight',
  }];
  contactForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.email),
    phoneNumber: new FormControl(''),
    service: new FormControl(''),
    message: new FormControl('', Validators.required)
  }, { validators: emailPhoneValidator });
  constructor() { }
  ngOnInit(): void {
    const timeline = gsap.timeline({defaults: {  x: 100 , opacity: 0}});
    timeline.from('.contact-us-animate', { stagger: 0.5});
    const timeline2 = gsap.timeline({ defaults: { duration: 1, y: -50, opacity: 0 } });
    timeline2.from('.contact-header-animate', { stagger: 0.6 });
  }

  onSubmit() {
    console.log(this.contactForm.value);
  }
}
