<section class="py-5 mx-2 text-center container">
  <div class="row py-lg-5">
    <div class="col-lg-6 col-md-8 mx-auto">
      <h1 class="font-weight-light home-header-animate text-primary">Globally connected, <span class="text-muted-yellow">Locally invested.</span></h1>
      <p class="lead text-muted home-header-animate"> As an agile
        business with an entrepreneurial approach, we pride ourselves in structuring flexible and innovative
        logistics &amp; supply chain solutions that meet the industry requirements of our clients.</p>
      </div>
  </div>
  <app-welcome-message></app-welcome-message>
</section>
<section class="services-animate">
  <app-services></app-services>
</section>
<div class="row  my-4">
  <div class="card text-center border-0">
    <div class="card-body">
      <blockquote class="blockquote mb-0">
        <p>Think like a man of action, act like a man of thought.</p>
        <footer class="blockquote-footer">Henry Louis Bergson</footer>
      </blockquote>
    </div>
  </div>
</div>
<app-footer></app-footer>

