<div class="row featurette align-items-center mx-3">
  <div class="col-md-7">
    <h2 class="featurette-heading welcome-animate">Welcome to <span class="text-muted-yellow">Aero-Ocean</span></h2>
    <p class="lead welcome-animate">Aero-ocean logistics was established in 2017 as a local freight forwarding company in South Africa.
      Trust and transparency form our core values and our ethics and services reflect quality and excellence in
      everything we do.</p>
    <p class="lead welcome-animate">We leverage our global synergies, indepth knowledge of local markets and vast experience in global
      logistics to deliver the best results for you.</p>
  </div>
  <div class="col-md-5">
    <img class="img-fluid rounded welcome-animate-img" src="assets/img/ship-on-ocean.jpg" alt="Ship Photo by David Dibert from Pexels">
  </div>
</div>
