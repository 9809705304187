<div class="container" role="main">
  <div class="row py-2">
    <nav class="navbar navbar-expand-lg bg-white fixed-top navbar-light rounded">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="assets/svgs/logo.svg" alt="" width="80" height="60">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#app-nav-bar"
          aria-controls="app-nav-bar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-md-center" id="app-nav-bar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" routerLink="" [routerLinkActive]="['active']" aria-current="page">Aero-Ocean</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/contact" [routerLinkActive]="['active']" aria-current="page">Contact
                us</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <div class="py-5">
    <router-outlet></router-outlet>
  </div>
</div>


<div class="d-none">Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a
    href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
<div class="d-none">Icons made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">Vectors Market</a>
  from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
<div class="d-none">Icons made by <a href="https://www.flaticon.com/authors/srip" title="srip">srip</a> from <a
    href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
<div class="d-none">Icons made by <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">Eucalyp</a> from <a
    href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
<div class="d-none">Icons made by <a href="https://www.flaticon.com/authors/nhor-phai" title="Nhor Phai">Nhor Phai</a> from <a
    href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
