import { Component, OnInit } from '@angular/core';
import { gsap, ScrollTrigger } from 'gsap/all';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  services = [
    {
      title: 'Air Freight',
      icon: 'assets/svgs/004-air-plane.svg',
      content: [
        {
          // tslint:disable-next-line: max-line-length
          line: 'Aero-Ocean logistics is based at the foreign airline terminal in Johannesburg with direct access to the airlines for easy offloading of specialised cargo.'
        },
        {
          // tslint:disable-next-line: max-line-length
          line: 'We offer a 24hour receiving and dispatching service, providing economical shipping with fast dedicated transit times.'
        }
      ]
    },

    {
      title: 'Sea Freight',
      icon: 'assets/svgs/003-freight.svg',
      content: [
        {
          // tslint:disable-next-line: max-line-length
          line: 'As the most common form of transportation in international trade today, customers will benefit from the availability and services that Aero-Ocean has to offer.'
        },
        {
          // tslint:disable-next-line: max-line-length
          line: 'With our global agency network, we can offer in depth understanding of local market requirements in advance eliminating delays and penalties.'
        }
      ]
    },
    {
      title: 'Road Freight',
      icon: 'assets/svgs/002-delivery-truck.svg',
      content: [
        {
          // tslint:disable-next-line: max-line-length
          line: 'Aero-Ocean’s integrated transport and logistics service includes road transport service that connects all major SADC regions into a single network.'
        },
        {
          // tslint:disable-next-line: max-line-length
          line: 'With our expertise and infrastructure, we can provide flexible and innovative solutions to reach your goals in terms of capacity, frequency and most importantly your costs.'
        }

      ]
    },
    {
      title: 'Freight Forwarding',
      icon: 'assets/svgs/005-checklist.svg',
      content: [
        {
          // tslint:disable-next-line: max-line-length
          line: 'Our dedicated Aero-Ocean team will firstly understand your unique challenges and thereafter continuously develop improved, personalised logistics solutions for your business.'
        },
        {
          // tslint:disable-next-line: max-line-length
          line: 'Our goal is to optimise your overall forwarding effectiveness by minimising risk and maximising efficiency, ultimately resulting in cost savings.'
        }
      ]
    },

    {
      title: 'Warehousing',
      icon: 'assets/svgs/001-warehouse.svg',
      content: [
        {
          // tslint:disable-next-line: max-line-length
          line: 'Aero-Ocean offers a wide range of storage and distribution solutions for almost every type of product from Fruit to Pharmaceuticals. You can take advantage of the different components, either collectively or individually.'
        },
        {
          // tslint:disable-next-line: max-line-length
          line: 'Our clients have the added advantage of having access to additional resources as their requirements fluctuate.'
        }
      ]
    },
    {
      title: 'Specialized Services',
      icon: 'assets/svgs/006-gear.svg',
      content: [
        {
          // tslint:disable-next-line: max-line-length
          line: 'At Aero Ocean we understand that the challenges and complexities are not common to all, and that a unique solution is often called for.'
        },
        {
          // tslint:disable-next-line: max-line-length
          line: 'Our projects team will strive to deliver a quality service and innovative end-to-end solutions at a competitive price'
        }
      ]
    },
  ];
  shadow: string;
  constructor() { }
  ngOnInit(): void {
  }
}
