import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { OurOfficesComponent } from './pages/our-offices/our-offices.component';
import { WelcomeMessageComponent } from './pages/welcome-message/welcome-message.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactValidityDirective } from './contact-validity.directive';
import { FooterComponent } from './pages/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    ContactUsComponent,
    OurOfficesComponent,
    WelcomeMessageComponent,
    ContactValidityDirective,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [ContactValidityDirective]
})
export class AppModule { }
